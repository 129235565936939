<template>
  <div>
    <team_manager
      v-if="view_type == 'add'"
      :match_id="match_id"
      :team_data="team_data"
      :view_type="view_type"
      :match_type="match_type"
       :season_id = "season_id"
    ></team_manager>
    <team_manager
      v-loading="loading"
      v-if="view_type == 'edit'"
      :match_id="match_id"
      :team_data="team_data"
      :view_type="view_type"
      :match_type="match_type"
      :season_id = "season_id"
    ></team_manager>
  </div>
</template>

<script>
import team_manager from "./team_manager";
import { mapActions } from "vuex";
export default {
  name: "edit_team",
  components: {
    team_manager,
  },
  data() {
    return {
      type: "s1",
      team_data: {},
      loading: false,
      match_id: "",
      view_type: "",
      match_type: "",
      season_id: ""
    };
  },
  async activated() {
    if (this.$route.query.view_type) {
      this.view_type = this.$route.query.view_type;
    }
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.match_id = this.$route.query.match_id;
    this.match_type = this.$route.query.match_type;
    this.season_id = this.$route.query.season_id,
    console.log("1111", this.match_type);
    if (this.view_type == "edit") {
      this.loading = true;
      let _id = this.$route.query._id;
      if (_id) {
        if (this.match_type !== "match_s4") {
          let { data, errorCode } = await this.$http.match.detail_team(
            this.match_id,
            _id
          );
          if (errorCode != "0000") {
            this.removeTabByPath(`/match/info/info_s1/add_team`);
            this.removeTabByPath(`/match/info/info_s1/edit_team`);
            return;
          }

          this.team_data = data;
        } else {
          let { data, errorCode } =
            await this.$http.match_subject.detail_team_new({ _id: _id });
          if (errorCode != "0000") {
            this.removeTabByPath(`/match/info/info_s1/add_team`);
            this.removeTabByPath(`/match/info/info_s1/edit_team`);
            return;
          }
          this.team_data = data;
        }
      } else {
        this.removeTabByPath(`/match/info/info_s1/add_team`);
        this.removeTabByPath(`/match/info/info_s1/edit_team`);
      }

      this.loading = false;
    } else {
      this.team_data = {};
    }
  },
  methods: {
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>

<style scoped></style>
